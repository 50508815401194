<template>
  <el-dialog class="main-container" :title="$t('form.groupUser.selectMembers')" :visible="visible" width="800px" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleDialogClose">
    <el-form ref="form" label-width="80px" :inline="true">
      <el-form-item>
        <el-input v-model="searchForm.data.phone_number" :placeholder="$t('form.members.phoneNumber')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.data.username" :placeholder="$t('form.members.userName') + '/' + $t('form.members.nickname')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick">{{ $t('button.search') }}</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        ref="multipleTable"
        class="interval-mb"
        :data="mainTable.data"
        style="width: 100%"
        max-height="400"
        v-loading="mainTable.loading"
        @selection-change="handleSelectionChange">
        <el-table-column width="55">
          <template>
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column :label="$t('form.groupUser.avatar')" width="220">
          <template slot-scope="scope">
            <div class="product-box">
              <div v-if="scope.row.avatar_url && scope.row.avatar_url !== 0" class="cover">
                <img :src="scope.row.avatar_url" class="img">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('form.groupUser.userName')" prop="name"></el-table-column>
        <el-table-column :label="$t('form.members.phoneNumber')" prop="phone_number" width="120"></el-table-column>
        <el-table-column :label="$t('table.common.operate')" fixed="right" width="220">
          <template slot-scope="scope">
            <el-button class="margintop10" size="mini" type="primary" @click="() => handleViewClick(scope.row.id)">{{ $t('button.view') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleMainTableSizeChange"
        @current-change="handleMainTableCurrentChange"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="mainTable.pagination.papeSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="mainTable.pagination.totalCount">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleDialogClose">{{ $t('button.cancel') }}</el-button>
      <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import dataDictionary from '@/utils/data-dictionary.js'
import * as membersApi from '@/services/members.js'

export default {
  name: 'productListDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    setVisible: {
      type: Function,
      default: () => false
    },
    activity: {
      type: Object,
      default: () => {}
    },
    shopList: {
      type: Array,
      default: () => []
    },
    supplierList: {
      type: Array,
      default: () => []
    },
    shopCategorieList: {
      type: Array,
      default: () => []
    },
    shopSubCategorieList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataDictionary,
      searchForm: {
        data: {
          username: null,
          phone_number: null
        }
      },
      mainTable: {
        loading: false,
        // 分页
        pagination: {
          papeSize: 50,
          current: 1,
          totalCount: 0
        },
        // 数据
        data: [],
        selectedRow: [],
        poi_ids: []
      }
    }
  },
  watch: {
    visible (newV, oldV) {
      const _this = this
      if (newV) {
        _this.mainTable.selectedRow = []
        _this.mainTable.pagination.papeSize = 50
        _this.mainTable.pagination.current = 1
        _this.mainTable.pagination.totalCount = 0
        _this.requestMainTableData()
      }
    }
  },
  mounted () {},
  methods: {
    dateFormat (date) {
      return Utils.dateFormat('YYYY-mm-dd HH:MM:SS', date)
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    setMainTableLoading (show) {
      const _this = this
      show ? _this.mainTable.loading = true : _this.mainTable.loading = false
    },
    requestMainTableData () {
      const _this = this
      const params = {
        ..._this.searchForm.data,
        page: _this.mainTable.pagination.current,
        per_page: _this.mainTable.pagination.papeSize
      }
      _this.setMainTableLoading(true)
      membersApi.getUserGroupSimpelistByType(this.activity.user_type, params).then(res => {
        _this.setMainTableLoading(false)
        const resData = res.data
        _this.mainTable.data = resData.users
        _this.mainTable.pagination.totalCount = resData.pagination.total_count
      }).catch(() => _this.setMainTableLoading(false))
    },
    handleMainTableSizeChange (val) {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.mainTable.pagination.papeSize = val
      _this.requestMainTableData()
    },
    handleMainTableCurrentChange (val) {
      const _this = this
      _this.mainTable.pagination.current = val
      _this.requestMainTableData()
    },
    handleDialogClose () {
      const _this = this
      _this.setVisible(false)
    },
    handleSearchClick () {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.requestMainTableData()
    },
    handleMainFormSubmitClick () {
      const _this = this
      if (_this.mainTable.selectedRow === null || _this.mainTable.selectedRow.length === 0) {
        _this.$message({
          message: _this.$t('form.activity.submitProductListDialogWarning1'),
          type: 'warning'
        })
      } else {
        console.log(_this.mainTable.selectedRow)
        _this.mainTable.poi_ids = []
        if (this.isObject(_this.mainTable.selectedRow)) {
          _this.mainTable.poi_ids.push(_this.mainTable.selectedRow.id)
        } else {
          _this.mainTable.selectedRow.map(item => {
            console.log(item.id)
            _this.mainTable.poi_ids.push(item.id)
          })
        }
        _this.$emit('onSubmitClick', _this.mainTable.poi_ids)
      }
    },
    isObject (obj) {
      return Object.prototype.toString.call(obj) === '[object Object]'
    },
    handleSelectionChange (val) {
      this.mainTable.selectedRow = val
      console.log(this.mainTable.selectedRow)
    },
    handleViewClick (id) {
      this.$router.push({
        name: 'userGroupMemberDetail',
        params: { id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .interval-mb {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 30%;
      margin-right: 12px;
      .img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        word-break: break-all;
      }
    }
  }
}
</style>
