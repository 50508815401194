<template>
  <div class="pcaap-container">
    <div>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" @click="handleAddProductClick">{{ $t('form.groupUser.addMembers') }}</el-button>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col>
          <el-button type="primary" :disabled="mainTable.multipleSelection.length < 1" @click="handleBatchDestroyClick(-1)">{{
            $t('text.batchDestroy')
          }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
            ref="mainTable"
            :data="mainTable.data"
            v-loading="mainTable.loading"
            style="width: 100%"
            max-height="700"
            @selection-change="handleMainTableSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="ID" prop="id.value"></el-table-column>
            <el-table-column :label="$t('form.groupUser.avatar')" width="320">
              <template slot-scope="scope">
                <div class="product-box">
                  <div v-if="scope.row.avatar.value && scope.row.avatar.value !== 0" class="cover">
                    <img :src="scope.row.avatar.value" class="img">
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('form.groupUser.userName')" prop="name.value"></el-table-column>
            <el-table-column :label="$t('form.groupUser.nickName')" prop="nickname.value"></el-table-column>
            <el-table-column :label="$t('table.common.operate')" fixed="right" width="220">
              <template slot-scope="scope">
                <el-button class="margintop10" size="mini" type="primary" @click="() => handleViewClick(scope.row.id)">{{ $t('button.view') }}</el-button>
                <el-button class="margintop10" size="mini" type="danger" @click="() => handleBatchDestroyClick(scope.row.id)">{{ $t('button.removeFromGroup') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleMainTableSizeChange"
            @current-change="handleMainTableCurrentChange"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="mainTable.pagination.papeSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="mainTable.pagination.totalCount"
          >
          </el-pagination>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" :disabled="activeStep <= 0" @click="handleMainOkClick">{{ $t('button.confirm') }}</el-button>
        </el-col>
      </el-row>
    </div>
    <s-product-list-dialog
      :visible="dialog.productList.visible"
      :setVisible="setProductListDialogVisible"
      :activity="routeParams"
      :shopList="shopList"
      :supplierList="supplierList"
      :shopCategorieList="shopCategorieList"
      :shopSubCategorieList="shopSubCategorieList"
      @onSubmitClick="handleProductListDialogSubmitClick"
    />
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import dataDictionary from '@/utils/data-dictionary.js'
import SProductListDialog from './components/productListDialog/index.vue'
import * as membersApi from '@/services/members.js'
export default {
  components: {
    SProductListDialog
  },
  props: ['activeStep', 'setActiveStep'],
  data () {
    return {
      dataDictionary,
      routeParams: null,
      activityDetail: null,
      shopList: [],
      supplierList: [],
      shopCategorieList: [],
      shopSubCategorieList: [],
      selectedProductId: null,
      mainTable: {
        loading: false,
        // 分页
        pagination: {
          papeSize: 50,
          current: 1,
          totalCount: 0
        },
        multipleSelection: [],
        // 数据
        data: []
      },
      mainForm: {
        data: {
          online: false, // 上架
          homepage_display: false, // 是否首页展示
          name: '', // 活动动名
          name_en: '', // 活动名(英文)
          date: null,
          begin_date: null, // 开始时间
          end_date: null, // 结束时间
          remote_image_url: null
        }
      },
      dialog: {
        productList: {
          visible: false
        },
        productInfo: {
          type: 0, // 0 新增 1 编辑
          visible: false,
          data: null,
          stock: []
        }
      }
    }
  },
  created () {
    const _this = this
    _this.routeParams = _this.$route.query
  },
  mounted () {
    this.requestMainTableData()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    setMainTableLoading (show) {
      const _this = this
      show ? _this.mainTable.loading = true : _this.mainTable.loading = false
    },
    setProductInfoDialogVisible (show) {
      const _this = this
      _this.dialog.productInfo.visible = show
    },
    setProductListDialogVisible (show) {
      const _this = this
      _this.dialog.productList.visible = show
    },
    requestMainTableData () {
      const _this = this
      _this.setMainTableLoading(true)
      return membersApi.getUserGroupMembers(_this.routeParams.user_group_id, {
        page: _this.mainTable.pagination.current,
        per_page: _this.mainTable.pagination.papeSize
      }).then(res => {
        _this.setMainTableLoading(false)
        const resData = res.data
        _this.mainTable.data = []
        _this.mainTable.pagination.totalCount = resData.pagination.total_count
        _this.$nextTick(() => {
          _this.mainTable.data = resData.users
        })
      }).catch(() => _this.setMainTableLoading(false))
    },
    handleProductListDialogSubmitClick (selectedProductId) {
      const _this = this
      _this.dialog.productInfo.type = 0
      _this.selectedProductId = selectedProductId
      const params = {
        user_group: {
          user_ids: selectedProductId
        }
      }
      _this.setGlobalLoading(true)
      membersApi.userGroupMembersAdd(this.routeParams.user_group_id, params).then(res => {
        _this.setGlobalLoading(false)
        _this.setProductListDialogVisible(false)
        _this.requestMainTableData()
      }).catch(() => _this.setGlobalLoading(false))
    },
    handleAddProductClick () {
      const _this = this
      _this.dialog.productList.visible = true
    },
    handleAddProductSuccess () {
      const _this = this
      _this.setActiveStep(2)
      _this.selectedProductId = null
      _this.setProductInfoDialogVisible(false)
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.requestMainTableData()
    },
    handleAddProductCancel () {
      const _this = this
      _this.selectedProductId = null
    },
    handleMainTableSizeChange (val) {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.mainTable.pagination.papeSize = val
      _this.requestMainTableData()
    },
    handleMainTableCurrentChange (val) {
      const _this = this
      _this.mainTable.pagination.current = val
      _this.requestMainTableData()
    },
    handleMainOkClick () {
      const _this = this
      _this.$router.replace({
        name: 'couponManagerOnlineCouponList'
      })
    },
    handleMainTableSelectionChange (val) {
      const _this = this
      _this.mainTable.multipleSelection = val
    },
    handleBatchDestroyClick (data = -1) {
      const _this = this
      const params = { user_ids: [] }
      let tip = _this.$t('text.confirmBatchDestroy')
      if (data === -1) {
        params.user_ids = _this.mainTable.multipleSelection.map(item => item.id.value)
      } else {
        params.user_ids.push(data.value)
        tip = _this.$t('text.confirmDeleteThisItem')
      }
      _this.$confirm(tip, _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        membersApi.userGroupMembersDelete(_this.routeParams.user_group_id, { user_group: params }).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.requestMainTableData()
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }).catch(err => err)
    },
    handleViewClick (data) {
      const _this = this
      _this.$router.push({
        name: 'userGroupMemberDetail',
        params: {
          id: data.value
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pcaap-container {
  .interval {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 40%;
      margin-right: 12px;
      .img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        word-break: break-all;
      }
    }
  }
}
</style>
